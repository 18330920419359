/* Set the background to black and text to white */
body {
  background-color: black;
  color: white;
  font-family: Roboto, sans-serif;
}


@keyframes fadeInOut {
  0% { opacity: 1; transform: translateY(0); }
  50% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

.song-list h2 {
  cursor: pointer;
  justify-content: center;
  width: 100%;
  display: flex;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

.song-list h2.animate {
  animation-name: fadeInOut;
}

.spinner-icon {
  animation: spin 1s linear infinite;
  font-size: 1em;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Visualizer section styling */
.visualizer-section {
  width: 100%;
  display: flex;
  justify-content: center;
}

.visualizer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;  /* Adjust width to fit content */
  height: auto;  /* Adjust height to fit content */
  margin-top: 20px; /* White border around the visualizer */
  padding: 0;  /* Remove padding to make the border fit closer */
  box-sizing: border-box;   /* Ensures the padding and border are included in the element's total width and height */
}

/* Song list styling */
.song-list ul {
  list-style-type: none;
  padding-left: 10px;
  padding-right: 10px;
}

/* Song item styling */
.song-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white; /* Ensure song titles are white */
}

/* Hover effect for song items */
.song-item:hover {
  background-color: #333; /* Slightly lighter black for hover */
}

/* Footer styling */
/* Footer styling */
footer {
  text-align: center;
  color: white;
  position: fixed; /* Fix the footer to the bottom of the screen */
  bottom: 0;      /* Align the footer to the bottom */
  width: 100%;    /* Ensure the footer spans the full width of the screen */
  background-color: black; /* Maintain the black background */
  padding: 10px; /* Add padding to give space around the footer content */
}

footer p:last-child {
  font-size: 0.8em; /* Make the last paragraph (for high-quality...) smaller */
}

/* Song Progress Bar styling */
.song-progress-bar {
  display: flex;
  align-items: center;
  color: white; /* Time labels color */
  font-size: 0.8em; /* Smaller font size for a more subtle display */
  padding: 5px 0; /* Vertical padding for spacing */
}

.song-progress-bar span {
  min-width: 40px; /* Ensures time labels don't get squished */
  text-align: center; /* Center-align the time text */
}

.song-progress-bar input[type="range"] {
  flex-grow: 1; /* Allows the slider to take up remaining space */
  -webkit-appearance: none;  /* Overrides default browser styles */
  height: 4px; /* Set the thickness of the slider */
  background: #FFFFFF; /* Dark grey, almost blending with the background */
  outline: none; /* Removes the outline */
  opacity: 0.5; /* Slightly see-through to blend with the background */
  transition: opacity 0.2s ease-in-out; /* Smooth transition for hover effects */
}

.song-progress-bar input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px; /* Width of the thumb */
  height: 12px; /* Height of the thumb */
  border-radius: 50%; /* Circular thumb */
  background: white; /* White thumb to stand out */
  cursor: pointer; /* Indicates it's draggable */
}

.song-progress-bar input[type="range"]::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

/* Enhances the slider thumb on hover */
.song-progress-bar input[type="range"]:hover::-webkit-slider-thumb {
  background: #ccc; /* Lighter grey when hovering */
}

.song-progress-bar input[type="range"]:hover::-moz-range-thumb {
  background: #ccc;
}
